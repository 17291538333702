<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <!-- Filtro por período -->
            <ul class="nav line-tabs borderless flex-center mb-5">
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'weekly'}"
                  v-on:click="setTempPeriod('weekly')" ref="view_type_weekly" data-toggle="pill" data-period="weekly">{{ $t('period.weekly') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'monthly'}"
                  v-on:click="setTempPeriod('monthly')" ref="view_type_monthly"  data-toggle="pill" data-period="monthly">{{ $t('period.monthly') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'yearly'}"
                  v-on:click="setTempPeriod('yearly')"  ref="view_type_yearly" data-toggle="pill" data-period="yearly">{{ $t('period.yearly') }}</a>
              </li>
            </ul>

            <!-- Filtros -->
            <h5 class="mb-0">Filtrar por:</h5>
            <form id="report-statement-weekly-list-filter" v-on:submit.prevent="applyFilter()">

              <!-- Período -->
              <h6 class="mb-3 mt-5">{{ $t('report.report_system.period') }}</h6>
              <div class="row">
                <div class="col-md-12">
                  <div class="md-form mb-0 row">
                    <div class="col-md-6">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filYear" id="year" name="year" ref="year" >
                          <option v-for="(item, index) in reportData.years" v-bind:key="index" :value="item"
                            :selected="filters.filYear === item" >{{ item }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.year') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod == 'weekly'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filWeek" id="week" name="week" ref="week" >
                          <option v-for="(week, index) in reportData.weeks" v-bind:key="index" :value="index"
                            :selected="filters.filWeek === parseInt(index)" >{{ week }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.week') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod == 'monthly'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filMonth" id="month" name="month" ref="month" >
                          <option v-for="(month, index) in reportData.months" v-bind:key="index" :value="index"
                            :selected="filters.filMonth === parseInt(index)" >{{ month}}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.month') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Por cliente -->
              <div>
                <div>
                  <h6 class="mb-3 mt-5">{{ $t('report.report_system.customer_label') }}</h6>
                  <multiple-select :options="customerList" :id-field="'id_customer'"
                    :placeholder="this.$i18n.t('form.customer.label')"
                    :fetchOptions="customerFetchOptions" :value="filters.filCliente" />
                </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}</button>
                    </div>
                    <div class="col-6">
                      <button type="button"  v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{ $t('filter.reset') }}</button>
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="getActiveFilterList" />
      </div>
      <div class="col-12 mt-2">
        <h5 >{{ getPeriodDescription }}</h5>
      </div>

      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-5">{{ $t('report.report_system.historical_usage') }}</h5>

            <div class="row">
              <div class="chart-container chart-container-total-toBoard-vs-boarded">
                <canvas id="total_access" style="height: 25.96vw !important; width: 600px;"></canvas>
                <div class="no-data total_toBoard_vs_boarded" style="display: none"></div>
              </div>
            </div>
            <div class="row chart-legend row justify-content-md-center">
              <div class="col-sm-3" v-for="(item, index) in legend" :key="index" :data-idx="index" >
                <!--<span class="badge-point rounded-circle  mr-2"></span><span>{{ $t('form.process.status.open') }}</span>-->
                <span class="badge-point mr-2"  v-bind:class="{'rounded-circle': (item.label === 'Abertos'), 'rounded-0': (item.label !== 'Abertos') }"  v-bind:style="{ backgroundColor: item.color }"></span>
                <span>{{ $t(item.label) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-5">{{ $t('report.report_system.user_usage') }}</h5>

            <div class="row">
              <div class="chart-container chart-container-total-toBoard-vs-boarded">
                <canvas id="access_by_user" style="height: 25.96vw !important; width: 600px;"></canvas>
                <div class="no-data total_toBoard_vs_boarded" style="display: none"></div>
              </div>
            </div>
            <div class="row chart-legend row justify-content-md-center">
              <div class="col-sm-3" v-for="(item, index) in legend" :key="index" :data-idx="index" >
                <!--<span class="badge-point rounded-circle  mr-2"></span><span>{{ $t('form.process.status.open') }}</span>-->
                <span class="badge-point mr-2"  v-bind:class="{'rounded-circle': (item.label === 'Abertos'), 'rounded-0': (item.label !== 'Abertos') }"  v-bind:style="{ backgroundColor: item.color }"></span>
                <span>{{ $t(item.label) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-5">{{ $t('report.report_system.user_usage_request') }}</h5>

            <div class="row">
              <div class="chart-container chart-container-total-toBoard-vs-boarded">
                <canvas id="most_active_users" style="height: 25.96vw !important; width: 600px;"></canvas>
                <div class="no-data total_toBoard_vs_boarded" style="display: none"></div>
              </div>
            </div>
            <div class="row chart-legend row justify-content-md-center">
              <div class="col-sm-3" v-for="(item, index) in legend" :key="index" :data-idx="index" >
                <!--<span class="badge-point rounded-circle  mr-2"></span><span>{{ $t('form.process.status.open') }}</span>-->
                <span class="badge-point mr-2"  v-bind:class="{'rounded-circle': (item.label === 'Abertos'), 'rounded-0': (item.label !== 'Abertos') }"  v-bind:style="{ backgroundColor: item.color }"></span>
                <span>{{ $t(item.label) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-5">{{ $t('report.report_system.customer_usage') }}</h5>

            <div class="row">
              <div class="chart-container chart-container-total-toBoard-vs-boarded">
                <canvas id="access_by_customer" style="height: 25.96vw !important; width: 600px;"></canvas>
                <div class="no-data total_toBoard_vs_boarded" style="display: none"></div>
              </div>
            </div>
            <div class="row chart-legend row justify-content-md-center">
              <div class="col-sm-3" v-for="(item, index) in legend" :key="index" :data-idx="index" >
                <!--<span class="badge-point rounded-circle  mr-2"></span><span>{{ $t('form.process.status.open') }}</span>-->
                <span class="badge-point mr-2"  v-bind:class="{'rounded-circle': (item.label === 'Abertos'), 'rounded-0': (item.label !== 'Abertos') }"  v-bind:style="{ backgroundColor: item.color }"></span>
                <span>{{ $t(item.label) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-5">{{ $t('report.report_system.customer_usage_request') }}</h5>

            <div class="row">
              <div class="chart-container chart-container-total-toBoard-vs-boarded">
                <canvas id="most_active_customers" style="height: 25.96vw !important; width: 600px;"></canvas>
                <div class="no-data total_toBoard_vs_boarded" style="display: none"></div>
              </div>
            </div>
            <div class="row chart-legend row justify-content-md-center">
              <div class="col-sm-3" v-for="(item, index) in legend" :key="index" :data-idx="index" >
                <!--<span class="badge-point rounded-circle  mr-2"></span><span>{{ $t('form.process.status.open') }}</span>-->
                <span class="badge-point mr-2"  v-bind:class="{'rounded-circle': (item.label === 'Abertos'), 'rounded-0': (item.label !== 'Abertos') }"  v-bind:style="{ backgroundColor: item.color }"></span>
                <span>{{ $t(item.label) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Últimos acessos -->
      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <h5 class="box-title mb-5">{{ $t('report.report_system.customer_last_access') }}</h5>

                <!-- Table -->
                <div class="table-responsive ">
                  <vuetable ref="vuetable"
                            :noDataTemplate="this.$i18n.t('noting to result')"
                            :api-url="quotesEndpoint"
                            :fields="vTableFields"
                            :sort-order="sortOrder"
                            :css="css.table"
                            pagination-path="pagination"
                            :per-page="20"
                            :multi-sort="multiSort"
                            :append-params="filter"
                            multi-sort-key="shift"
                            :http-options="httpOptions"
                            track-by="id"
                            @vuetable:pagination-data="onPaginationData"
                            @vuetable:loading="onLoading"
                            @vuetable:loaded="onLoaded"
                            @vuetable:load-error="handleLoadError"
                            @vuetable:cell-clicked="onRowClicked">
                    <template slot="actions" slot-scope="props">
                      <div class="custom-actions">
                        <a class="btn btn-link"
                           v-bind:href="mountLink('QuoteEdit', props.rowData)">
                          <i class="text-primary  ti-pencil font-20"></i>
                        </a>
                        <a class="btn btn-link"
                           v-on:click="onDelete(props.rowData)">
                          <i class="text-primary  ti-trash font-20"></i>
                        </a>
                      </div>
                    </template>
                  </vuetable>

                </div>
                <vuetable-pagination ref="pagination"
                                     :css="css.pagination"
                                     @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination><!-- END Table -->
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Vuetable, { VuetablePagination } from 'vuetable-2'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import 'chart.js/dist/Chart.min.css'

// Components
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
// MultipleSelect
import MultipleSelect from '@/components/Forms/MultipleSelect.vue'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// DatePicker
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import SystemService from '@/services/SystemService'
import CustomerService from '@/services/CustomerService'

// Por padrão label vem habilitada para todos os gráficos (Removendo para poder colocar apenas nos que desejar)
Chart.plugins.unregister(ChartDataLabels)

export default {
  name: 'ReportSystemUsage',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('report.title') + ' - ' + this.$i18n.t('report.report_system.title') + ' - %s'
    }
  },
  data () {
    return {
      quotesEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/user/last/access',
      tempPeriod: 'weekly',
      customerList: [],
      reportData: {
        data: [],
        months: [
          'Janeiro',
          'Fevereiro'
        ]
      },
      hasData: false,
      legend: [],
      totalAccess: null,
      totalAccessData: {
        files: [],
        labels: []
      },
      accessByUser: null,
      accessByUserData: {
        files: [],
        labels: []
      },
      mostActiveUsers: null,
      mostActiveUsersData: {
        files: [],
        labels: []
      },
      accessByCustomer: null,
      accessByCustomerData: {
        files: [],
        labels: []
      },
      mostActiveCustomers: null,
      mostActiveCustomersData: {
        files: [],
        labels: []
      },
      isLoading: true,
      fullPage: true,
      activeFilterList: {
        period: null,
        year: null,
        week: null,
        month: null
      },
      filter: {},
      fieldsListTable: [
        {
          name: 'username',
          title: 'Usuário',
          sortField: 'u.username',
          formatter: (value) => this.$util.formatEmpty(value, undefined, ' - ')
        },
        {
          name: 'cliente',
          title: 'Cliente',
          sortField: 'p.nome',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'lastaccess',
          title: 'Último acesso',
          sortField: 'lastaccess',
          formatter: (value) => this.formatDateTime(value)
        }
      ],
      sortOrder: [
        { field: 'lastaccess', direction: 'desc' }
      ],
      multiSort: true,
      httpOptions: {
        headers: {
          Authorization:
            'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    MultipleSelect,
    FilterSideBar,
    Loading,
    FilterContainer,
    Vuetable,
    VuetablePagination
  },
  beforeMount () {
    this.isLoading = true
    /**
     * Returns the week number for this date.  dowOffset is the day of week the week
     * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
     * the week returned is the ISO 8601 week number.
     * @param int dowOffset
     * @return int
     */
    // eslint-disable-next-line
    Date.prototype.getWeek = function (dowOffset) {
      // default dowOffset to zero
      dowOffset = dowOffset !== undefined && typeof (dowOffset) === 'number' ? dowOffset : 0
      let newYear = new Date(this.getFullYear(), 0, 1)
      // the day of week the year begins on
      let day = newYear.getDay() - dowOffset
      day = (day >= 0 ? day : day + 7)
      let daynum = Math.floor((this.getTime() - newYear.getTime() -
        (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1
      let weeknum
      // if the year starts before the middle of a week
      if (day < 4) {
        weeknum = Math.floor((daynum + day - 1) / 7) + 1
        if (weeknum > 52) {
          let nYear = new Date(this.getFullYear() + 1, 0, 1)
          let nday = nYear.getDay() - dowOffset
          nday = nday >= 0 ? nday : nday + 7
          // if the next year starts before the middle of the week, it is week #1 of that year
          weeknum = nday < 4 ? 1 : 53
        }
      } else {
        weeknum = Math.floor((daynum + day - 1) / 7)
      }
      return weeknum
    }
    this.filters.filCliente = []
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    this.buildCharts()

    if (this.user) {
      this.getReportStatementData()
    }

    this.$jQuery('#begin_date, #end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    showDuracao (duracao) {
      if (duracao === undefined || isNaN(duracao)) {
        return '00:00'
      }

      duracao = Math.floor(duracao)
      let horas = Math.floor(duracao / 60)
      let minutos = duracao % 60

      if (horas < 0) {
        horas = horas * -1.0
      }

      if (minutos < 0) {
        minutos = minutos * -1.0
      }

      return horas.toString().padStart(2, '0') + ':' + minutos.toString().padStart(2, '0')
    },
    getTotal (desvios) {
      let total = 0

      for (let i in desvios) {
        let desvioGrupo = desvios[i]
        total += desvioGrupo.qtd
      }

      return total
    },
    getTotalDuracao (duracao) {
      let count = 0
      let total = 0

      for (let i in duracao) {
        let duracaoGrupo = duracao[i]
        total += duracaoGrupo.total_minutos
        count += duracaoGrupo.n_cotacoes
      }

      return total / count
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
    },
    handleLoadError (response) {
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    },
    setTempPeriod (item) {
      this.tempPeriod = item
    },
    getDescription (current, previous, label, prefix, valueFormatter) {
      if (current === undefined || isNaN(current)) {
        current = 0
      }

      if (previous === undefined || isNaN(previous)) {
        previous = 0
      }

      let lessThanInfo = 'na semana anterior.'
      let equalInfo = 'a semana anterior.'

      if (this.filter.period === 'yearly') {
        lessThanInfo = 'no ano anterior.'
        equalInfo = 'o ano anterior.'
      } else if (this.filter.period === 'monthly') {
        lessThanInfo = 'no mês anterior.'
        equalInfo = 'o mês anterior.'
      }

      let diferenca = current - previous
      prefix = prefix || ''
      if ((diferenca) > 0) {
        return prefix + ((valueFormatter) ? valueFormatter(diferenca) : this.showNumber(diferenca)) + ' ' + label + ' a mais que ' + lessThanInfo
      } else if ((diferenca) === 0) {
        return prefix + ' ' + label + ' iguais ' + equalInfo
      } else {
        return prefix + ((valueFormatter) ? valueFormatter(diferenca) : this.showNumber(diferenca * -1.0)) + ' ' + label + ' a menos que ' + lessThanInfo
      }
    },
    buildFilters () {
      this.filter.period = this.filters.filPeriodo
      this.tempPeriod = this.filters.filPeriodo

      /**
       *  Não primeira abertura seta o filtros com o padrão
       *   - Mês: Atual
       *   - Ano: Atual
       *   - Colaborador: Usuário autenticado
       *  */

      let today = new Date()
      if (!this.filters.filYear && !this.filters.filWeek && !this.filters.filMonth && (!this.filters.filEmployees || this.filters.filEmployees.length <= 0)) {
        this.filters.filYear = today.getFullYear()
        this.filters.filWeek = today.getWeek()
        this.filters.filMonth = today.getMonth() + 1

        this.$store.dispatch('setAppliedFilter', this.filters)
      }

      if ((!this.filters.filWeek)) {
        this.filters.filWeek = today.getWeek()
      }

      if ((!this.filters.filMonth)) {
        this.filters.filMonth = today.getMonth() + 1
      }

      this.filter.year = this.filters.filYear
      this.filter.week = this.filters.filWeek
      this.filter.month = this.filters.filMonth
      this.filter.customer = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []
    },
    // Filter
    applyFilter () {
      this.employeeList = []
      // Period
      if (this.$refs.view_type_weekly.classList.contains('active')) {
        this.filters.filPeriodo = 'weekly'
      } else if (this.$refs.view_type_monthly.classList.contains('active')) {
        this.filters.filPeriodo = 'monthly'
      } else if (this.$refs.view_type_yearly.classList.contains('active')) {
        this.filters.filPeriodo = 'yearly'
      }

      // Filter Processes
      this.filters.filYear = this.$refs.year ? parseInt(this.$refs.year.value) : null
      this.filters.filWeek = this.$refs.week ? parseInt(this.$refs.week.value) : null
      this.filters.filMonth = this.$refs.month ? parseInt(this.$refs.month.value) : null

      this.buildFilters()
      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      this.$refs.vuetable.refresh()

      this.getReportStatementData()
      /* Todo: Reload Charts */
      // this.getReportStatementData()
    },
    resetFilter (event) {
      this.hideQuichSidebar()
      // hasFilter: false
      let today = new Date()

      this.filters.filYear = today.getFullYear()
      this.filters.filWeek = today.getWeek()
      this.filters.filMonth = today.getMonth() + 1
      this.filters.filPeriodo = 'weekly'
      this.filters.filEmployees = []

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.getReportStatementData()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    showNumber (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','
      thousandsSep = thousandsSep || '.'
      if (number !== parseInt(number)) {
        decimals = 2
      }

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    showInteger (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    // End Filter
    getReportStatementData () {
      let me = this
      me.isLoading = true

      if (this.$store.getters.userInfo) {
        SystemService.getUsage(this.filter.year, this.filter.week, this.filter).then(res => {
          this.hasData = res.data.hasData

          me.reportData = res.data

          if (this.hasData) {
            this.totalAccessData = res.data.generalUsageData
            this.accessByUserData = res.data.userAccessData
            this.mostActiveUsersData = res.data.mostActiveUsersData
            this.accessByCustomerData = res.data.customerAccessData
            this.mostActiveCustomersData = res.data.mostActiveCustomersData
            me.addChartBarData(this.totalAccess, res.data.generalUsageData)
            me.addChartBarData(this.accessByUser, res.data.userAccessData)
            me.addChartBarData(this.mostActiveUsers, res.data.mostActiveUsersData)
            me.addChartBarData(this.accessByCustomer, res.data.customerAccessData)
            me.addChartBarData(this.mostActiveCustomers, res.data.mostActiveCustomersData)
          } else {
            // Chart Bar
            me.addChartBarData(this.totalAccess, [])
            me.addChartBarData(this.accessByUser, [])
            me.addChartBarData(this.mostActiveUsers, [])
            me.addChartBarData(this.accessByCustomer, [])
            me.addChartBarData(this.mostActiveCustomers, [])
          }

          me.isLoading = false
        })
      }
    },
    addChartBarData (chart, data) {
      this.legend = []
      chart.data.labels = []
      chart.data.datasets = []

      chart.update()

      chart.data.labels = data.labels
      if (data.hasData) {
        for (var propertyName in data.dataset) {
          let item = data.dataset[propertyName]

          if (!item.show) {
            continue
          }

          for (let valuePropertyName in item.data) {
            let value = item.data[valuePropertyName]

            let dataSet = {
              label: item.title,
              attribute: value.label,
              total: value.total,
              backgroundColor: value.color,
              stack: 1,
              data: value.values
            }

            if (value.type) {
              dataSet.type = value.type
              dataSet.fill = false
              dataSet.borderWidth = 2
              dataSet.lineTension = 0.1
              dataSet.borderColor = value.color
            }

            let legend = {
              title: propertyName,
              label: value.label,
              color: value.color
            }

            this.legend.push(legend)

            chart.data.datasets.push(dataSet)
            chart.update()
          }
        }
      }
    },
    asset (value) {
      let baseUrl = process.env.VUE_APP_BaseURL + '/'

      let assetUrl = baseUrl + value

      return assetUrl
    },
    buildCharts () {
      this.totalAccess = this.newBarChart('total_access')
      this.accessByUser = this.newBarChart('access_by_user')
      this.mostActiveUsers = this.newBarChart('most_active_users')
      this.accessByCustomer = this.newBarChart('access_by_customer')
      this.mostActiveCustomers = this.newBarChart('most_active_customers')
    },
    newBarChart (selector) {
      let MONTHS_SH = [
        (this.$i18n.t('date.month.Jan')).substring(0, 3),
        (this.$i18n.t('date.month.Feb')).substring(0, 3),
        (this.$i18n.t('date.month.Mar')).substring(0, 3),
        (this.$i18n.t('date.month.Apr')).substring(0, 3),
        (this.$i18n.t('date.month.May')).substring(0, 3),
        (this.$i18n.t('date.month.Jun')).substring(0, 3),
        (this.$i18n.t('date.month.Jul')).substring(0, 3),
        (this.$i18n.t('date.month.Aug')).substring(0, 3),
        (this.$i18n.t('date.month.Sep')).substring(0, 3),
        (this.$i18n.t('date.month.Oct')).substring(0, 3),
        (this.$i18n.t('date.month.Nov')).substring(0, 3),
        (this.$i18n.t('date.month.Dec')).substring(0, 3)
      ]

      // Show no results message
      Chart.plugins.register({
        afterDraw: function (chart) {
          if (chart.data.datasets.length === 0) {
            // No data is present
            var ctx = chart.chart.ctx
            var width = chart.chart.width
            var height = chart.chart.height
            // chart.clear()

            ctx.save()
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.font = "20px normal 'Helvetica Nueue'"
            ctx.fillText('Sem informações no período', width / 2, height / 2)

            ctx.restore()
          }
        }
      })

      var config = {
        type: 'bar',
        data: {
          labels: MONTHS_SH

        },
        options: {
          legend: {
            display: false
          },
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              title: function (tooltipItem, data) {
                let label = data.datasets[tooltipItem[0].datasetIndex].label

                if (label) {
                  return data['labels'][tooltipItem[0]['index']] + ' (' + label + ')'
                } else {
                  return data['labels'][tooltipItem[0]['index']]
                }
              },
              label: function (tooltipItem, data) {
                let currentColumn = data.datasets[tooltipItem.datasetIndex].attribute
                let currentStack = data.datasets[tooltipItem.datasetIndex].stack
                let valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

                // Loop through all datasets to get the actual total of the index
                var total = 0
                for (var i = 0; i < data.datasets.length; i++) {
                  let dataSet = data.datasets[i]

                  if (dataSet.stack === currentStack) {
                    total += data.datasets[i].data[tooltipItem.index]
                  }
                }

                // If it is not the last dataset, you display it as you usually do
                if (data.datasets[tooltipItem.datasetIndex].total) {
                  if (total > 0) {
                    return [currentColumn + ' : ' + total]
                  } else {
                    return false
                  }
                } else { // .. else, you display the dataset and the total, using an array
                  if (valor > 0) {
                    return currentColumn + ' : ' + valor
                  } else {
                    return false
                  }
                }
              },
              afterLabel: function (tooltipItem, data) {
                /* var dataset = data['datasets'][0];
                  var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
                  return '(' + percent + '%)'; */
              }
            },
            backgroundColor: '#FFF',
            titleFontSize: 14,
            titleSpacing: 10,
            titleMarginBottom: 10,
            bodySpacing: 4,
            titleFontColor: '#3f3f3f',
            bodyFontColor: '#333',
            bodyFontSize: 13,
            cornerRadius: 3,
            yPadding: 10,
            xPadding: 10,
            displayColors: true
          },
          elements: {
            rectangle: {
              borderWidth: 2
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          axes: {
            display: false
          },
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.90,
              barPercentage: 1.0,
              gridLines: {
                color: 'rgba(0, 0, 0, 0)'
              },
              scaleLabel: {
                display: true,
                labelString: this.$i18n.t('period.title')
              }
            }],
            yAxes: [{
              stacked: false,
              categoryPercentage: 1.0,
              barPercentage: 1.0,
              scaleLabel: {
                display: true,
                labelString: this.$i18n.t('quantity')
              }

            }]
          }
        }
      }

      var ctxProcesses = document.getElementById(selector).getContext('2d')
      return new Chart(ctxProcesses, config)
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onRowClicked (row) {

    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        CustomerService.getCustomers(filters).then(res => {
          _this.customerList = res.data.data
          resolve(_this.customerList)
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  computed: {
    vTableFields () {
      return this.fieldsListTable
    },
    fieldsTable () {
      return [
        {
          name: 'nome',
          title: this.$i18n.t('Descrição'),
          sortField: 'nome',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'qtd',
          title: 'Quantidade',
          sortField: 'qtd',
          formatter: (value) => this.$util.formatEmpty(value),
          dataClass: 'text-center',
          titleClass: 'text-center'
        }
      ]
    },
    getActiveFilterList () {
      let activedFilterList = {
        period: null,
        year: null
      }

      if (this.filters.filPeriodo === 'weekly') {
        activedFilterList = {
          period: null,
          year: null,
          week: null
        }
      } else if (this.filters.filPeriodo === 'monthly') {
        activedFilterList = {
          period: null,
          year: null,
          month: null
        }
      }

      return activedFilterList
    },
    showEmployeeFilter () {
      if (this.user) {
        let showEmployee = false
        if (this.user.roles.filter(role => role === 'ROLE_DIRECTOR').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_SUPER_ADMIN').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_FINANTIAL').length > 0) {
          showEmployee = true
        }

        return showEmployee
      } else {
        return false
      }
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    },
    getPeriodDescription () {
      let period = 'Semanal'

      if (this.filter.period === 'yearly') {
        period = 'Anual'
      } else if (this.filter.period === 'monthly') {
        period = 'Mensal'
      }
      return period
    }
  },
  watch: {
    user: function () {
      this.getReportStatementData()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  .chart-container {
    position: relative;
    margin: auto;
    width: 100%;
    padding: 0 60px;
  }

  .chart-legend {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .disableLabel span{
    background-color: rgba(132,117,78,0.6) !important;
    border-color: rgba(132,117,78,0.1) !important;
  }
</style>
